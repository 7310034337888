// @ts-nocheck
import { FunctionComponent } from 'react';

import './Participation.scss';

import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/esm/Container';

import Layout from '../Layout/Layout';
import Intro from './Intro/Intro';
import ParkModal from './ParkModal/ParkModal.js';

import bassens2 from '../../assets/image/participation/windpark/bassens-2.jpg';
import birkholz from '../../assets/image/participation/windpark/birkholz.jpg';
import broderstorf2 from '../../assets/image/participation/windpark/broderstorf-2.jpg';
import friedland3 from '../../assets/image/participation/windpark/friedland-3.jpg';
import gremersdorf from '../../assets/image/participation/windpark/gremersdorf.jpg';
import landin from '../../assets/image/participation/windpark/landin.jpg';
import lindauRussdorf from '../../assets/image/participation/windpark/lindau-russdorf.jpg';
import radegast from '../../assets/image/participation/windpark/radegast.jpg';
import wismar from '../../assets/image/participation/windpark/wismar.jpg';

const Participation: FunctionComponent = () => {
  const translation = useSelector(state => state.translation);

  const downloads = {
    birkholz: [
      {
        name: translation['participation.park.birkholz.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Jahresbericht_2022.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_012023_V1.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_032023-2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+MBBF+WP+Birkholz+GmbH+%26+Co.+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_Jahresbericht+2023_V2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_042024pdf.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_052024+(1).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_062024+(002).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/Birkholz+II%2BVI_Bericht_122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+MBBF+WP+Birkholz+GmbH+%26+Co.+Betriebs+KG.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/WP+Birkolz+II%2BBirkholz+VI_Monatsbericht_012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Birkholz+II/WP+Birkholz+II+%2B+VI_Monatsbericht_022025.pdf'
        ]
      }
    ],
    broderstorf2: [
      {
        name: translation['participation.park.broderstorf-2.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Jahresbericht_2022_V2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+JWP+Jade+WP+GmbH+%26+Co.+III.+Betriebs+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Jahresbericht+2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_042024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_052024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/Broderstorf+II_Bericht_122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+JWP+Jade+WP+GmbH+%26+Co.+III.+Betriebs+KG.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/WP+Broderstorf+II_Monatsbericht_012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Broderstorf+II/WP+Broderstorf+II_Monatsbericht_022025.pdf'
        ]
      }
    ],
    friedland3: [
      {
        name: translation['participation.park.friedland-3.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Jahresbericht_2022.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+062023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+072023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+082023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+092023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+102023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+112023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+122023.pdf_2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+JWP+Jade+WP+GmbH+%26+Co.+II.+Betriebs+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Jahresbericht+2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+042024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+052024+(1).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/Friedland+III_Bericht+122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Friedland+III/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+JWP+Jade+WP+GmbH+%26+Co.+II.+Betriebs+KG.pdf'
        ]
      }
    ],
    gremersdorf: [
      {
        name: translation['participation.park.gremersdorf.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Jahresbericht_2022.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Jahresbericht_2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_042024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_052024+(1).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/Gremersdorf_Monatsbericht_122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/WP+Gremersdorf_Monatsbericht_012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Gremersdorf/WP+Gremersdorf_Monatsbericht_022025.pdf'
        ]
      }
    ],
    landin: [
      {
        name: translation['participation.park.landin.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+JWP+Jade+WP+GmbH+%26+Co.+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Jahresbericht_2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_042024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_052024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Monatsbericht_082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Monatsbericht_092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Monatsbericht_102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Monatsbericht_112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Monatsbericht_122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+JWP+Jade+WP+GmbH+%26+Co.+Betriebs+KG.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Landin/Landin_Bericht_022025.pdf'
        ]
      }
    ],
    lindauRussdorf: [
      {
        name: translation['participation.park.lindau-russdorf.name.lindau'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Jahresbericht_2022.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Jahresbericht_2022-2.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+MBBF+WP+GmbH+%26+Co.+V.+Betriebs+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Jahresbericht_2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_042024_.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_052024+(1).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Monatsbericht_122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+MBBF+WP+GmbH+%26+Co.+V.+Betriebs+KG.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Lindau/Lindau_Bericht_022025.pdf'
        ]
      },
      {
        name: translation['participation.park.lindau-russdorf.name.russdorf'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Monatsbericht_122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+MBBF+WP+GmbH+%26+Co.+V.+Betriebs+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/Ru%C3%9Fdorf_Jahresbericht_2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_042024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_052024+(1).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+MBBF+WP+GmbH+%26+Co.+V.+Betriebs+KG.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Lindau+u.+Ru%C2%B7dorf/Ru%C2%B7dorf/WP+Ru%C3%9Fdorf_Monatsbericht_022025.pdf'
        ]
      }
    ],
    radegast: [
      {
        name: translation['participation.park.radegast.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Radegast/Radegast_Bericht_+012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Radegast/Radegast_Bericht_+022023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Radegast/Radegast_Bericht_+032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Radegast/Radegast_Bericht_+042023.pdf'
        ]
      }
    ],
    wismar: [
      {
        name: translation['participation.park.wismar.name'],
        items: [
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+012023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+022023_Korrektur.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+032023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+042023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+052023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+062023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+072023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+082023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+092023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+102023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+112023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+122023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/24-01-02+Fragebogen+Sonderbetriebsausgaben-einnahmen+2023+-+JWP+Jade+WP+GmbH+%26+Co.+IV.+Betriebs+KG+(Internet).pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+012024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+022024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+032024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_+Jahresbericht+2023.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+042024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+052024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+062024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+072024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+082024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+092024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+102024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+112024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+122024.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/25-02-07+Fragebogen+Sonderbetriebsausgaben-einnahmen+2024+-+JWP+Jade+WP+GmbH+%26+Co.+IV.+Betriebs+KG.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+012025.pdf',
          'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.jade.homepage/Berichte/Wismar/Wismar_Bericht+022025.pdf'
        ]
      }
    ],
    bassens2: [
      {
        name: translation['participation.park.bassens-2.name'],
        items: []
      }
    ]
  };

  return (
    <div id="participation">
      <Layout menuSelect={'participation'}>
        <Intro />
        <div className="soll-ist-vergleich">
          <div className="cta">
            <div className="download">
              {translation['participation.soll-ist-vergleich.download']}
            </div>
            <div className="label">{translation['participation.soll-ist-vergleich.label']}</div>
          </div>
        </div>
        <Container>
          <div className="park-list">
            <ParkModal
              image={birkholz}
              name={translation['participation.park.birkholz.name']}
              downloads={downloads.birkholz}
            >
              <div className="meta">
                <p className="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>MBBF Windpark Birkholz GmbH &amp; Co.KG</strong>
                </p>
                <p className="text-align-center">
                  Anzahl der WEA:<strong>3</strong>
                </p>
                <p className="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p className="text-align-center">
                  Nabenhöhe:&nbsp;<strong>100 m</strong>
                </p>
                <p className="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p className="text-align-center">
                  Installierte Gesamtleistung:&nbsp;<strong>4,5 MW</strong>
                </p>
                <p className="text-align-center">
                  Bundesland: <strong>Brandenburg</strong>
                </p>
                <p className="text-align-center" id="yui_3_17_2_2_1415443333292_5088">
                  PLZ Gemeinde: <strong>15831 Birkholz</strong>
                </p>
              </div>
              <div class="text">
                <p id="yui_3_17_2_2_1415443333292_5087">
                  Der Standort der Windenergieanlagen liegt ca. 14 km nordöstlich vom Stadtgebiet
                  Berlin in der Ostbrandenburgischen Platte, einer Moränenlandschaft zwischen den
                  Urstromtalnetzen Oder, Havel und Spree. Die Windenergieanlagen werden zwischen den
                  Ortschaften Blumberg und Birkholz direkt am Autobahnkreuz Schwanebeck am Berliner
                  Ring errichtet.
                </p>
                <p id="yui_3_17_2_2_1415443333292_5086">
                  Die Umgebung der Windparkfläche ist gekennzeichnet durch ein allgemein flaches
                  Terrain mit nur sehr leichten Erhebungen. Der Standort selbst liegt auf einer
                  solchen Erhebung auf etwa 75 -80 m ü. NN in einem freien Weidegebiet mit nur
                  vereinzelten Bäumen und Sträuchern. Im Umkreis von etwa 10 km sind die
                  Höhenunterschiede eher gering, in Richtung Nordosten steigt das Höheniveau sanft
                  an. Die orographischen Einflüsse sind hinsichtlich des Windpotentials jedoch
                  unbedeutend.
                </p>
                <p id="yui_3_17_2_2_1415443333292_5085">
                  Die Landschaft wird überwiegend landwirtschaftlich genutzt, mit nur wenigen
                  kleineren Waldflächen. Quer über den Standort zieht sich eine Hochspannungstrasse.
                </p>
                <p id="yui_3_17_2_2_1415443333292_5084">
                  Hinsichtlich der Beschaffenheit der Oberfläche und damit Rauhigkeiten liegen im
                  Umkreis von 10 km des Standortes zwei strukturierte Großräume vor. Das
                  Erscheinungsbild der südlichen bis westlichen Sektoren ist geprägt vom Stadtrand
                  Berlin sowie ein Siedlungsband, welches sich von Nordwesten beginnend bei Bernau
                  bis zum südsüdwestlich liegenden Zentrum der Stadt Berlin erstreckt. Südlich des
                  Siedlungsbandes und damit südwestlich des Standortes ist die Oberfläche deutlich
                  offener und begünstigend für Windströmungen. In den nördlichen bis südöstlichen
                  Sektoren sind landwirtschaftlich genutzte Flächen mit Baum- und Buschreihen
                  anzutreffen.
                </p>
                <p>
                  Im Einzugsbereich des Standortes befinden sich keine Hindernisse, die signifikante
                  störende Einflüsse darstellen.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={broderstorf2}
              name={translation['participation.park.broderstorf-2.name']}
              downloads={downloads.broderstorf2}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>JWP Jade Windpark GmbH &amp; Co.III Betriebs KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA: <strong>3</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: 10<strong>0 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung: <strong>4,5 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Mecklenburg-Vorpommern</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>18184 Broderstorf</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Der Windpark Broderstorf II befindet sich im Küstenbereich des Bundeslandes
                  Mecklenburg-Vorpommern. Aufgrund der Nähe zur Ostsee (ca. 11 km) sind am Standort
                  günstige Bedingungen für die Windenergienutzung anzutreffen.
                </p>
                <p>
                  Die geplanten Windenergieanlagen werden auf einer landwirtschaftlich genutzten
                  Fläche errichtet, die ein Höhenniveau von rund 30 m Ü.NN aufweist. Die
                  Standortregion ist gekennzeichnet durch ein allgemein flaches Terrain und wird im
                  Norden durch die Ortschaften Harmstorf und Albertsdorf, im Süden durch die
                  Ortschaften Neuendorf, Pastow und Neu Broderstorf und im Osten durch die Siedlung
                  Neu Fienstorf begrenzt. Die Windenergieanlagen stehen in unmittelbarer Nähe zu
                  110- und 220-kV-Freileitungen verschiedener Unternehmen.
                </p>
                <p>
                  Zu den 220-kV-Leitungen der Vattenfall Europe Transmission GmbH wird ein Abstand
                  von über 115,5 m (genau XXX m zur Windenenergieanlage A) eingehalten. Zu den 110
                  kV-Leitungen der WEMAG und der e.dis wird auch die Kipphöhe von 138,5 m
                  berücksichtigt. Die Landschaft im Umkreis von ca. 3 km ist hauptsächlich
                  charakterisiert durch landwirtschaftliche Nutzflächen.
                </p>
                <p>
                  Weiterhin gibt es einige Feldgehölz- und Baumreihen an Wegrändern. Die in der Nähe
                  befindlichen Wälder, abgesehen von Steinfelder Holz oder Kriegholz im Osten sind
                  verhältnismäßig klein und liegen nicht in der Hauptwindrichtung sie haben daher
                  keinen bedeutsamen Einfluss auf die Rauhigkeit. Westlich des Standorts fällt das
                  Gelände bis zu der auf Meeresniveau liegenden Unterwarnow in ca. 6 km Entfernung
                  ab. Im Osten ist das Höhenniveau nur geringfügig höher als der Standort.
                </p>
                <p>
                  Die nächstgelegenen größeren Erhebungen der „Rostocker Schweiz“ erreichen 66 m ü.
                  NN und befinden sich ca. 4,5 km südlich des Standorts. Der größte
                  Rauhigkeitseinfluss besteht mit der nächst größeren Stadt Rostock, die sich ca. 6
                  km westlich befindet.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={friedland3}
              name={translation['participation.park.friedland-3.name']}
              downloads={downloads.friedland3}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>JWP Jade Windpark GmbH &amp; Co.II Betriebs KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA: <strong>2</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: 10<strong>0 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung: <strong>3,0 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Mecklenburg-Vorpommern</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>17098 Friedland</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Der Windpark Friedland III befindet sich nordwestlich der Uckermark in Vorpommern
                  im Bundesland Mecklenburg-Vorpommern auf einer Freifläche nordwestlich der Stadt
                  Friedland. Die nächste größere Stadt ist das ca. 20 km südwestlich gelegene
                  Neubrandenburg.
                </p>
                <p>
                  Die Landschaft der Standortregion ist durch weite und freie, nahezu ausschließlich
                  ackerbaulich genutzte Flächen gekennzeichnet. Charakteristisch für die Landschaft
                  ist das leicht wellige Gelände. Die geplanten Windenergieanlagen befinden sich in
                  einer Höhe von ca. 20 m ü. NN.
                </p>
                <p>
                  Nach Westen steigt das Gelände zu den nördlichen Ausläufern des Werder leicht auf
                  Höhen um ca. 40 m ü. NN an. In einer Entfernung von ca. 30 km in süd- und
                  südwestlicher Richtung befindet sich eine Endmoränenlandschaft, zu der das Gelände
                  leicht auf Höhen von bis zu 100 m ü. NN ansteigt. Von Südwesten nach Nordosten
                  verläuft das Tal der Datze bzw. des Peene-Südkanals mit einer Höhenlage von ca. 15
                  m ü. NN. Der 15 km südöstlich gelegene Brohmer Berg sowie der 10 km nordöstlich
                  gelegene Hellberg stellen mit 131 m ü. NN bzw. 54 m ü. NN die größten Erhebungen
                  dar.
                </p>
                <p>
                  Hinsichtlich der Rauhigkeit liegen im direkten Standortgebiet von gut 10 km
                  Durchmesser landwirtschaftlich genutzte Großflächen vor, welche zum einen durch
                  Baum- und Buschreihen und zum anderen durch die Ortschaften Salow und Bresewitz
                  unterbrochen sind.
                </p>
                <p>
                  Die Stadt Friedland südöstlich des Standortes dehnt sich über eine Fläche von 5
                  km2 aus und bringt relativ hohe Rauhigkeiten aus dieser Richtung. Im Nordwesten in
                  ca. 2 km Entfernung befindet sich der Salower Wald. Weitere größere Waldregionen
                  erstrecken sich in einer Entfernung von über 5 km in südwestlicher und westlicher
                  Richtung.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={gremersdorf}
              name={translation['participation.park.gremersdorf.name']}
              downloads={downloads.gremersdorf}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>MBBF Windpark Gremersdorf GmbH &amp; Co.KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA:&nbsp;<strong>3</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: 8<strong>0 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung:&nbsp;<strong>4,5 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Mecklenburg-Vorpommern</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>18461 Gremersdorf-Buchholz</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Der Windpark Gremersdorf im Gemeindegebiet Gremersdorf-Buchholz im Norden des
                  Bundeslandes Mecklenburg-Vorpommern liegt zwischen den Städten Stralsund (ca. 26
                  km Nordorst), Greifswald (ca. 36 km Ost), Rostock (ca. 48 West) und Demmin (ca. 27
                  km Südost) im Landkreis Nordvorpommern. Die Ortschaften Gremersdorf (ca. 1,8 km
                  Südost), Rekentin (ca. 1,8 km Südwest), Pöglitz (ca. 1,5 km Nord) und Angerode
                  (1,8 km Nordost) sowie die Flussläufe der Trebel im Süden und der Blinden Trebel
                  im Westen Rahmen den geplanten Windparkstandort ein.
                </p>
                <p>
                  Das Gebiet befindet sich im nordöstlichen Flachland Mecklenburg-Vorpommerns am
                  Rande des einseitigen Urstromtals der Trebel zwischen der Ausgleichsküste der
                  Mecklenburger Bucht und der Ostsee im Norden und der Mecklenburgischen Schweiz im
                  Süden. Das Landschaftsbild in der näheren Umgebung wird hauptsächlich durch die
                  Nutzung, weniger durch das Relief geprägt: Landwirtschaftlich genutzte Flächen
                  (überwiegend Ackerland, aber auch Grünland) in Einheit mit kleineren Ortschaften
                  und einigen Wald- und Forstflächen insbesondere nördlich und westlich des
                  Standortes sind vorherrschend.
                </p>
                <p>
                  Die Landschaft im Umkreis von ca. 10 km um den Standort ist charakterisiert durch
                  offene landwirtschaftliche Nutzflächen, ländliche Bebauungen sowie zahlreiche
                  klein- und großflächige Waldgebiete. Die Geländeoberfläche ist eben bis leicht
                  wellig. Am Standort beträgt die Höhe über Normalnull (ü. NN) ca. 17 m. In den
                  Niederungen der Trebel und Blinden Trebel fällt die mittlere Höhe ü. NN auf ca. 1
                  m ab.
                </p>
                <p>
                  In nördlicher Richtung ist die Landschaft bis zum Wald (Buschkoppel, ca. 500 m
                  entfernt) offen. Danach schließen sich die Ortslagen Pöglitz (ca. 1,5 km
                  Entfernung) und Neumühl (ca. 3,2 km Entfernung) an. Weiter nordöstlich, ab ca. 2,7
                  km Entfernung, beginnen großflächige Waldgebiete, die den Ort Franzburg in
                  südlicher und östlicher Richtung umranden.
                </p>
                <p>
                  In östlicher Richtung ist eine freie Anströmung bis Gremersdorf, partiell bis ca.
                  3,4 km Entfernung (Hohenbarnekow und Vorland), gegeben. Danach schließen sich
                  großflächige Wälder an. Ab ca. 11 km Entfernung beginnt die Bebauung von Grimmen.
                </p>
                <p>
                  In südlicher Richtung wird die Landschaft stark durch den Verlauf der Trebel (ca.
                  2,6 km Entfernung) geprägt. Die Geländeoberfläche fällt in Richtung des
                  Flussbettes der Trebel langsam ab und ist überwiegend frei. Weiter südlich liegen
                  ab einer Entfernung von ca. 5 km großflächige Waldgebiete (u. a. Stremlower Forst,
                  Kickenkamp, Deyelsdorfer Forst).
                </p>
                <p>
                  In südwestlicher Richtung ist eine freie Anströmung bis ca. 2,5 km (Trebel) und in
                  nordwestlicher Richtung bis zu einem kleinen Wald (ca. 600 m Entfernung) gegeben.
                  Danach treten mehrere Waldgebiete im Uferbereich der Blinden Trebel (ca. 2,4 km
                  Entfernung) auf. Weiter westlich zeigt sich die Landschaft offener, bis ab ca. 5,5
                  km Entfernung in südwestlicher Richtung die Bebauung der Kleinstadt Tribsees
                  beginnt. Ab ca. 8 km Entfernung erstrecken sich im bis auf 1 m ü. NN abfallenden
                  Gelände großflächige Wälder (Naturschutzgebiet Rauhes Moor).
                </p>
                <p>
                  Als Hindernis im Nahbereich ist die Baumreihe westlich in Richtung Pöglitz zu
                  berücksichtigen.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={landin}
              name={translation['participation.park.landin.name']}
              downloads={downloads.landin}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>JWP Jade Windpark GmbH &amp; Co. Betriebs KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA: <strong>1</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: 8<strong>0 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung: <strong>1,5 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Brandenburg</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>16278 Landin</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Die Windenergieanlage befindet sich im Gemeindegebiet Mark Landin an der Grenze
                  zur Gemeinde Schwedt-Heinersdorf im Norden des Bundeslandes Brandenburg (Landkreis
                  Uckermark). Die Windparkfläche liegt nordöstlich der Stadt Schwedt (ca. 7 km)
                  zwischen der Stadt Heinersdorf und der Industrieansiedlung von Landin, direkt
                  angrenzend an den Windpark Schwedt-Heinersdorf, der aus neun Windenergieanlagen
                  des Typs GE 1.5 SL mit 100 m Nabenhöhe besteht.
                </p>
                <p>
                  Die nähere Umgebung des Standortes ist geprägt durch Ackerflächen. Im Norden ist
                  das Gelände durch die Passow-Mürowsche Kavelheide, im Westen durch Heinersdorf und
                  im Süden durch die Eisenbahnlinie zwischen Angermünde und Schwedt begrenzt. In
                  weiterer Entfernung überwiegt in allen Richtungen die landwirtschaftliche Nutzung
                  des Geländes. Diese wird durch kleinere Ortschaften, Waldstücke und dichte
                  Baumreihen unterbrochen. Besonders im Norden dehnen sich die Waldflächen relativ
                  weit aus.
                </p>
                <p>
                  Die Standortregion ist durch leicht wellige Strukturen gekennzeichnet. Im Umkreis
                  von etwa zwei Kilometern betragen die Höhendifferenzen weniger als 10 m.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={lindauRussdorf}
              name={translation['participation.park.lindau-russdorf.name']}
              downloads={downloads.lindauRussdorf}
            >
              <div class="meta" data-block-type="2" id="block-1145cd0bc2fbb74611e3">
                <div class="sqs-block-content">
                  <p class="text-align-center">
                    Name der Gesellschaft:
                    <br />
                    <strong>MBBF Windpark GmbH &amp; Co. V. Betriebs KG</strong>
                  </p>
                  <p class="text-align-center">
                    Anzahl der WEA: <strong>2</strong>
                  </p>
                  <p class="text-align-center">
                    Anlagentyp:
                    <br />
                    <strong>
                      <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                    </strong>
                  </p>
                  <p class="text-align-center">
                    Nabenhöhe: 10<strong>0 m</strong>
                  </p>
                  <p class="text-align-center">
                    Rotordurchmesser:&nbsp;<strong>77 m</strong>
                  </p>
                  <p class="text-align-center">
                    Installierte Gesamtleistung: <strong>3</strong>
                    <strong>,0 MW</strong>
                  </p>
                  <p class="text-align-center">
                    Bundesland: <strong>Thüringen/Sachsen</strong>
                  </p>
                  <p class="text-align-center">
                    PLZ Gemeinde:
                    <br />
                    <strong>07613 Heideland/09212 Limbach/Oberfrohna</strong>
                  </p>
                </div>
              </div>
              <div class="text">
                <p>
                  <strong>
                    <span>Lindau</span>
                  </strong>
                  <br />
                  Der Standort der Windkraftanlage in Lindau liegt 287 m ü. NN und ca. 1 km
                  nordnordwestlich der Ortsmitte von Lindau und 1 bis 2 km westlich der BAB A9. Die
                  Höhe des umgebenden Geländes bewegt sich zwischen 150 m ü. NN und 300 m ü. NN. Im
                  Vergleich zur Umgebung ist die Lage des Standortes in mehrfacher Richtung leicht
                  exponiert, so dass vor allem in der Nabenhöhe von 100 m über Grund
                  Ertragszuschläge zu erwarten sind.
                </p>
                <p>
                  Die Umgebung des Standortes ist im Umkreis von 1 km überwiegend offen und wird
                  meist landwirtschaftlich genutzt.
                </p>
                <p>
                  In der weiteren Standortumgebung befinden sich überwiegend offene Flächen. Kleine
                  Waldgebiete deren Verteilung recht homogen ist und eine Reihe kleiner, locker
                  bebauter Siedlungen führen bedingt zu Veränderungen der Rauhigkeit in einer
                  Bezugshöhe von 10 m über Grund. In einer Nabenhöhe von 100 m über Grund sind
                  jedoch keine diesbezüglichen Einflüsse mehr nachzuweisen.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <span>Rußdorf</span>
                  </strong>
                  <br />
                  Der Anlagenstandort im Ortsteil Rußdorf der Stadt Limbach-Oberfrohna befindet sich
                  im Vorland des Erzgebirges etwa 15 km entfernt vom Zentrum der Stadt Chemnitz. Die
                  Region ist dem südlichen Rand des Mittelsächsischen Lößlehmgebiets zuzuordnen. Der
                  Standort liegt im Norden/Nordwesten von Rußdorf in einer Höhe von 362 m ü. NN. Die
                  Nabenhöhe der Windkraftanlage liegt somit 462 m ü. NN. Die nähere Umgebung im
                  Umkreis von 1 km ist überwiegend offen und wird meist landwirtschaftlich genutzt.
                </p>
                <p>
                  Vorherrschend zeigt das weitere Umfeld landwirtschaftliche Strukturen. Von der
                  relativ homogenen Struktur hebt sich das Stadtgebiet von Limbach-Oberfrohna im
                  Osten und die weitgehend geschlossene Bewaldung um Steinberg (401 m ü. NN),
                  Langenberger Höhe (484 m ü. NN), Kapellberg (431 m ü. NN) und Totenstein (483 m ü.
                  NN) ab. Der Waldstreifen zieht sich in einer Distanz von 3 bis 5 km, in einem
                  Bogen ostsüdöstlich bis zum südsüdwestlichen Sektor hin. In Abhängigkeit von der
                  Entfernung zum Standort und der vom Wind überstrichene Fläche resultiert hieraus
                  eine mäßige Erhöhung der Rauhigkeit.
                </p>
                <p>
                  Die bewaldeten Erhebungen im Südosten stellen darüber hinaus ein orographisches
                  Hindernis dar, das luvseitig, zumindest in niedrigen Höhen über Grund zu
                  Minderungen der Windgeschwindigkeit führt. Expositions-Vorteile des Standortes bei
                  Anströmung aus anderen Richtungen gleichen diese eher leichten Defizite aber mehr
                  als aus.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={radegast}
              name={translation['participation.park.radegast.name']}
              downloads={downloads.radegast}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>MBBF Windpark Radegast GmbH &amp; Co. Betriebs KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA: <strong>4</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: 8<strong>0 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung: 6<strong>,0 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Mecklenburg-Vorpommern</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>18239 Bad Doberan</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Der Standort der Windkraftanlagen liegt südwestlich von Rostock in der
                  Moränenlandschaft der Mecklenburgischen Seenplatte. Die Entfernung zur Ostsee
                  beträgt etwa 21 km. Die Lage ist dem Ausschnitt der topografischen Karte zu
                  entnehmen. Es ist eine weite offene Landschaft, die durch kleine Hügelketten der
                  Endmoränen und breite Flusstäler geprägt ist. Am Osthang einer dieser Hügelketten
                  liegt der Standort in etwa 50 m ü. NN. Nach Westen steigt das Gelände bis auf
                  Höhen von knapp über 100 m an. Nach Osten fällt es ab ins Tal der Warnow, die sich
                  hier in Ostseenähe schon fast auf Normalnull dahinschlängelt.
                </p>
                <p>
                  Die Landschaft wird in den Niederungen überwiegend landwirtschaftlich genutzt, mit
                  nur wenigen Heckenreihen zwischen dem großflächigen Acker- und Weideland. Die
                  Moränenhügel sind teilweise großflächig bewaldet. Der Standort liegt auf einer
                  welligen, fast ebenen Fläche, die entlang der Landkreisgrenze durch einen
                  Heckensaum begrenzt wird. Den Abschluss im Nordwesten bildet die Autobahn.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={wismar}
              name={translation['participation.park.wismar.name']}
              downloads={downloads.wismar}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>JWP Jade Windpark GmbH &amp; Co. IV. Betriebs KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA: <strong>6</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>GE 1.5sl (GENERAL ELECTRIC Wind Energy GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: 10<strong>0 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser:&nbsp;<strong>77 m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung: 9<strong>,0 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Brandenburg</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>23966 Wismar</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Der Windpark Wismar liegt an der Grenze zwischen den beiden Bundesländern
                  Brandenburg und Mecklenburg-Vorpommern im Landkreis Uckermark (Brandenburg). Der
                  Standort befindet sich zwischen den Ortschaften Strasburg (3 km östlich),
                  Blumenhagen (3 km westsüdwestlich) und Groß Luckow (ca. 2 km südsüdwestlich).
                  Ansonsten befinden sich im westlichen bis südlichen Bereich lediglich vereinzelte
                  Kleinsiedlungen in einer Entfernung von 2 – 4 km. Die nächsten größeren Städte
                  sind das ca. 33 km westlich gelegene Neubrandenburg und das ca. 53 km östlich
                  gelegene Stettin (Polen).
                </p>
                <p>
                  Die Landschaft macht in der Näheren Umgebung des Standortes den Eindruck eines
                  Hügellandes und ist für die Region typisch durch weite und freie nahezu
                  ausschließlich ackerbaulich genutzte Flächen geprägt. Durch die großen
                  landwirtschaftlichen Schläge und der eher selten anzutreffenden Wälder und
                  Baumgruppen wird eine ungehinderte Windströmung begünstigt. Die geplanten
                  Windenergieanlagen befinden sich in einer Höhe von ca. 40 m ü. NN.
                </p>
                <p>
                  In der näheren Umgebung betragen die Höhenunterschiede ca. 15 – 30 m. Die nächst
                  größeren Erhebungen sind ca. 5 km nordwestlich im Klepelshagener Forst mit bis zu
                  133 m ü. NN. In westlicher und südwestlicher Richtung in einer Entfernung von ca.
                  1 bis 1,5 km befinden sich die Ortschaften Ravensmühle und Linchenshöh. Die
                  westlich in einer Entfernung von ca. 3 bis 4 km gelegene Stadt Strasburg stellt in
                  der Hauptwindrichtung die größte Rauhigkeit dar. Die nördlich des Standortes
                  gelegenen Wälder wirken sich hingegen kaum ertragsmindernd aus. Wesentlichen
                  Einfluss auf die Erträge haben die bereits bestehenden und die noch geplanten
                  Windenergieanlagen am Standort.
                </p>
              </div>
            </ParkModal>
            <ParkModal
              image={bassens2}
              name={translation['participation.park.bassens-2.name']}
              // downloads={downloads.bassens2}
            >
              <div class="meta">
                <p class="text-align-center">
                  Name der Gesellschaft:
                  <br />
                  <strong>Bassens Windpark GmbH &amp; Co. Bürgerpark II KG</strong>
                </p>
                <p class="text-align-center">
                  Anzahl der WEA:&nbsp;<strong>3</strong>
                </p>
                <p class="text-align-center">
                  Anlagentyp:
                  <br />
                  <strong>
                    <span>E-82 (ENERCON GmbH)</span>
                  </strong>
                </p>
                <p class="text-align-center">
                  Nabenhöhe: <strong>108 m</strong>
                </p>
                <p class="text-align-center">
                  Rotordurchmesser: <strong>82</strong>
                  <strong>&nbsp;m</strong>
                </p>
                <p class="text-align-center">
                  Installierte Gesamtleistung:&nbsp;<strong>6,0 MW</strong>
                </p>
                <p class="text-align-center">
                  Bundesland: <strong>Niedersachsen</strong>
                </p>
                <p class="text-align-center">
                  PLZ Gemeinde: <strong>26434 Wangerland</strong>
                </p>
              </div>
              <div class="text">
                <p>
                  Der Windpark Bassens befindet im Küstenbereich des Bundeslandes Niedersachsen im
                  Landkreis Friesland. Der Standort befindet sich in der Gemeinde Wangerland ca. 3
                  km südlich der Nordsee und 6 km westlich der offenen Jade-Weser-Mündung. Die
                  nächst größere Ortschaft ist das ca. 2,8 km südlich gelegene Hohenkirchen.
                </p>
                <p>
                  Die weitere Umgebung der Windparkfläche ist durch ebenes Marschland mit
                  ausgedehnten freien landwirtschaftlich genutzten Flächen geprägt.
                </p>
                <p>
                  Im Osten befindet sich der bereits bestehende Windpark mit 34 Windenergieanlagen
                  des Typs AN Bonus 600 kW/44 mit 55 m Nabenhöhe. Des Weiteren stehen südöstlich
                  zwei Windenergieanlagen des Typs Enercon E40 mit 42 m Nabenhöhe sowie südwestlich
                  eine Windenergieanlage des Typs Tacke TW 600 mit 50 m Nabenhöhe. Eine weitere
                  Windenergieanlage vom Typ Vestas V 39 mit einer Nabenhöhe von 42 m befindet sich 6
                  km nordöstlich des Standortes.
                </p>
                <p>
                  Im Südwesten und Westen nimmt der Anteil der Rauhigkeit durch einzelne Siedlungen
                  und die Dörfer Mederns und Friederikensiel leicht zu. Größere Siedlungsräume mit
                  höherer Bebauung sind erst im weiteren Umfeld zu finden. Hier können die ca. 12 km
                  südlich gelegene Stadt Jever, die ca. 14 km südwestlich gelegne Stadt Wittmund
                  sowie die ca. 23 km südöstlich gelegene Stadt Wilhelmshaven genannt werden.
                </p>
                <p>
                  Aufgrund der Nähe zur Nordsee und der relativ großen Entfernung zu größeren
                  Siedlungsräumen sind am Standort günstige Bedingungen für die Windenergienutzung
                  anzutreffen.
                </p>
              </div>
            </ParkModal>
          </div>
        </Container>
        <div className="line one"></div>
      </Layout>
    </div>
  );
};

export default Participation;
